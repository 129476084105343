import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import ko from './ko.json';
import en from './en.json';
import jp from './jp.json';

i18n
	.use(LanguageDetector) // 사용자 언어 탐지
	.use(initReactI18next)
	.init({
		resources: {
			...ko,
			...en,
			...jp
		},
		fallbackLng: {
			default: ['ko']
		},
		// debug: true,
		interpolation: {
			escapeValue: false
		}
	});

export default i18n;
