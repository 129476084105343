import {
	Box,
	Container,
	Grid,
	Typography,
	useMediaQuery,
	useTheme
} from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';

import images from 'assets/images';

const MonthText = ({ month }) => {
	const { t } = useTranslation();
	const theme = useTheme();
	const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));

	return (
		<Typography
			variant={matchUpMd ? 'h13' : 'caption2'}
			fontWeight={700}
			color={'black1'}
		>
			{t(`히스토리.${month}`)}
		</Typography>
	);
};

const DescriptionBox = ({ title, subtitle }) => {
	const { t, i18n } = useTranslation();
	const theme = useTheme();
	const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));

	return (
		<Box>
			<Typography
				variant={matchUpMd ? 'caption2' : 'caption4'}
				fontWeight={500}
				color={'black1'}
				display={'block'}
				letterSpacing={i18n.language === 'jp' && '-0.05rem'}
			>
				<Trans i18nKey={title} />
			</Typography>
			{subtitle && (
				<Typography
					variant={matchUpMd ? 'caption3' : 'caption6'}
					color={'gray6'}
					display={'block'}
					mt={'0.8rem'}
				>
					<Trans i18nKey={subtitle} />
				</Typography>
			)}
		</Box>
	);
};

const History = () => {
	const { t, i18n } = useTranslation();
	const theme = useTheme();
	const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));

	return (
		<Container id={'company-history'} maxWidth={'lg'} className={'px-0'}>
			<Grid
				container
				py={matchUpMd ? '10rem' : '7rem'}
				px={matchUpMd ? 0 : '2rem'}
			>
				<Grid
					item
					xs={12}
					display={'flex'}
					justifyContent={matchUpMd ? 'center' : 'flex-start'}
				>
					<Typography variant={matchUpMd ? 'h5' : 'h10'} color={'black1'}>
						{t('히스토리.히스토리')}
					</Typography>
				</Grid>
				<Grid
					item
					xs={12}
					display={'flex'}
					justifyContent={matchUpMd ? 'center' : 'flex-start'}
				>
					<Typography
						variant={matchUpMd ? 'caption1' : 'caption3'}
						color={'black1'}
					>
						{t('히스토리.콘텐츠마당의 현재와 미래')}
					</Typography>
				</Grid>
				{/* ===================================== 2024 ===================================== */}
				<Grid
					item
					xs={12}
					container
					direction={'row'}
					justifyContent={matchUpMd ? 'center' : 'flex-start'}
					mt={matchUpMd ? '10rem' : '4.8rem'}
				>
					<Typography variant={matchUpMd ? 'h2' : 'h13'}>
						{t('히스토리.2024년')}
					</Typography>
				</Grid>
				{/* ===================================== 2024.05 ===================================== */}
				<Container maxWidth={'md'} sx={{ mt: matchUpMd ? '6rem' : '2.5rem' }}>
					<Grid container>
						<Grid item xs={2} md={2}>
							<MonthText month={'5월'} />
						</Grid>
						<Grid item xs={10} md={10} pr={matchUpMd ? '2.5rem' : 0}>
							<DescriptionBox
								title={
									'히스토리.2024.주)스카이라이브티브이 ENA 와 ‘ENA 케이팝업 차트쇼 K POP UP CHART SHOW)’ 공식 제휴사업 계약 체결'
								}
								subtitle={
									'히스토리.2024.ENA 신규 음악 예능 ‘ENA 케이팝업 차트쇼 (6월 14일 방송예정) 공식 투표 및 글로벌 마케팅 독점 제휴사업을 체결하였습니다.'
								}
							/>
						</Grid>
					</Grid>
				</Container>
				{/* ===================================== 2024.01 ===================================== */}
				<Container maxWidth={'md'} sx={{ mt: matchUpMd ? '6rem' : '2.5rem' }}>
					<Grid container>
						<Grid item xs={2} md={2}>
							<MonthText month={'1월'} />
						</Grid>
						<Grid item xs={10} md={10} pr={matchUpMd ? '2.5rem' : 0}>
							<DescriptionBox
								title={
									'히스토리.2024.이데일리엠(주)와 KGMA(Korea Grand Music Awards) 공식 제휴사업 계약 체결'
								}
								subtitle={
									"히스토리.2024.엔터테인먼트, 스포츠 전문지 일간스포츠가 새롭게 선보이는 '코리아 그랜드 뮤직 어워즈'(KGMA) 공식 투표 및 마케팅 제휴사업을 체결하였습니다."
								}
							/>
						</Grid>
					</Grid>
				</Container>
				{/* ===================================== 2023 ===================================== */}
				<Grid
					item
					xs={12}
					container
					direction={'row'}
					justifyContent={matchUpMd ? 'center' : 'flex-start'}
					mt={matchUpMd ? '10rem' : '4.8rem'}
				>
					<Typography variant={matchUpMd ? 'h2' : 'h13'}>
						{t('히스토리.2023년')}
					</Typography>
				</Grid>
				{/* ===================================== 2023.07 ===================================== */}
				<Container maxWidth={'md'} sx={{ mt: matchUpMd ? '6rem' : '2.5rem' }}>
					<Grid container>
						<Grid item xs={2} md={2}>
							<MonthText month={'7월'} />
						</Grid>
						<Grid item xs={10} md={10} pr={matchUpMd ? '2.5rem' : 0}>
							<DescriptionBox
								title={
									'히스토리.2023.SBS 유니버스 티켓 공식 마케팅 제휴사업 계약 체결'
								}
								subtitle={
									'히스토리.2023.SBS 유니버스 티켓,  공식 온라인 투표사업 및 마케팅 운영계약 체결하였습니다.'
								}
							/>
						</Grid>
					</Grid>
				</Container>
				{/* ===================================== 2023.02 ===================================== */}
				<Container maxWidth={'md'} sx={{ mt: matchUpMd ? '4.8rem' : '2.5rem' }}>
					<Grid container>
						<Grid item xs={2} md={2}>
							<MonthText month={'2월'} />
						</Grid>
						<Grid item xs={10} md={10} pr={matchUpMd ? '2.5rem' : 0}>
							<DescriptionBox
								title={
									'히스토리.2023.(사)한국연예제작자협회 드림콘서트 ‘슈퍼루키’ 사업 MOU 체결'
								}
								subtitle={
									'히스토리.2023.실력을 갖춘 슈퍼루키를 양성하기 위하여 연제협의 회원사들과 함께 기술 지원 및 자원을 제공합니다.'
								}
							/>
						</Grid>
					</Grid>
				</Container>
				{/* ===================================== 2023.01 ===================================== */}
				<Container maxWidth={'md'} sx={{ mt: matchUpMd ? '4.8rem' : '3.6rem' }}>
					<Grid container>
						<Grid item xs={2} md={2}>
							<MonthText month={'1월'} />
						</Grid>
						<Grid item xs={10} md={10}>
							<DescriptionBox
								title={'히스토리.2023.32회 서울가요대상 글로벌 마케팅 운영'}
								subtitle={
									'히스토리.2023.32회 서울가요대상 공식 파트너로, 공식 온라인 투표사업 및 오프라인 글로벌 마케팅 운영하였습니다.'
								}
							/>
						</Grid>
					</Grid>
				</Container>
				<Container maxWidth={'lg'} sx={{ mt: matchUpMd ? '2.4rem' : '1.6rem' }}>
					<Grid container>
						<Box
							className={'CompanyHistory-Img-Wrapper'}
							pt={
								matchUpMd ? 'calc(400 / 1200 * 100%)' : 'calc(200 / 335 * 100%)'
							}
						>
							<img src={images.CompanyHistory2} />
						</Box>
					</Grid>
				</Container>
				{/* ===================================== 2022 ===================================== */}
				<Grid
					item
					xs={12}
					container
					direction={'row'}
					justifyContent={matchUpMd ? 'center' : 'flex-start'}
					mt={matchUpMd ? '10rem' : '7rem'}
				>
					<Typography variant={matchUpMd ? 'h2' : 'h13'}>
						{t('히스토리.2022년')}
					</Typography>
				</Grid>
				{/* ===================================== 2022.11 ===================================== */}
				<Container maxWidth={'md'} sx={{ mt: matchUpMd ? '6rem' : '2.5rem' }}>
					<Grid container>
						<Grid item xs={2} md={2}>
							<MonthText month={'11월'} />
						</Grid>
						<Grid item xs={10} md={10} pr={matchUpMd ? '2.5rem' : 0}>
							<DescriptionBox
								title={
									'히스토리.2022.핀테크전문기업 ‘핑거’ 콘텐츠마당 지분 투자'
								}
							/>
						</Grid>
					</Grid>
				</Container>
				{/* ===================================== 2022.11 ===================================== */}
				<Container maxWidth={'md'} sx={{ mt: matchUpMd ? '2.8rem' : '1.2rem' }}>
					<Grid container>
						<Grid item xs={2} md={2} />
						<Grid item xs={10} md={3} pr={matchUpMd ? '7.6rem' : 0}>
							<DescriptionBox title={'히스토리.2022.팬캐스트 서비스 론칭'} />
						</Grid>
						{matchUpMd && (
							<Grid item md={7}>
								<Box
									className={'CompanyHistory-Img-Wrapper'}
									pt={'calc(240 / 588 * 100%)'}
								>
									<img src={images.CompanyHistory4} />
								</Box>
							</Grid>
						)}
					</Grid>
				</Container>
				{!matchUpMd && (
					<Container maxWidth={'lg'} sx={{ mt: '1.2rem' }}>
						<Grid item xs={12}>
							<Box
								className={'CompanyHistory-Img-Wrapper'}
								pt={'calc(240 / 588 * 100%)'}
							>
								<img src={images.CompanyHistory4} />
							</Box>
						</Grid>
					</Container>
				)}
				{/* ===================================== 2022.07 ===================================== */}
				<Container maxWidth={'md'} sx={{ mt: matchUpMd ? '4.8rem' : '3.6rem' }}>
					<Grid container>
						<Grid item xs={2} md={2}>
							<MonthText month={'7월'} />
						</Grid>
						<Grid item xs={10} md={10}>
							<DescriptionBox
								title={
									'히스토리.2022.일본 마호캐스트 K-POP 대중문화산업 콘텐츠 육성사업 MOU 체결'
								}
							/>
						</Grid>
					</Grid>
				</Container>
				{/* ===================================== 2022.07 ===================================== */}
				<Container maxWidth={'md'} sx={{ mt: matchUpMd ? '2.8rem' : '1.6rem' }}>
					<Grid container>
						<Grid item xs={2} md={2} />
						<Grid item xs={10} md={10}>
							<DescriptionBox
								title={
									matchUpMd
										? '히스토리.2022.(사)한국연예제작자협회 K-POP ROOKIE 공동사업 MOU체결'
										: '히스토리.2022.M(사)한국연예제작자협회 K-POP ROOKIE 공동사업 MOU체결'
								}
							/>
						</Grid>
					</Grid>
				</Container>
				{/* ===================================== 2022.05 ===================================== */}
				<Container maxWidth={'md'} sx={{ mt: matchUpMd ? '4.8rem' : '3.6rem' }}>
					<Grid container>
						<Grid item xs={2} md={2}>
							<MonthText month={'5월'} />
						</Grid>
						<Grid item xs={10} md={10}>
							<DescriptionBox
								title={'히스토리.2022.(주)스포츠서울 IP 콘텐츠사업 계약 체결'}
							/>
						</Grid>
					</Grid>
				</Container>
				{/* ===================================== 2022.03 ===================================== */}
				<Container maxWidth={'md'} sx={{ mt: matchUpMd ? '4.8rem' : '3.6rem' }}>
					<Grid container>
						<Grid item xs={2} md={2}>
							<MonthText month={'3월'} />
						</Grid>
						<Grid item xs={10} md={10}>
							<DescriptionBox
								title={'히스토리.2022.(주)콘텐츠마당 설립'}
								subtitle={
									'히스토리.2022.(주)콘텐츠마당 법인을 설립하여 본격적인 글로벌 K-플랫폼 사업이 시작되었습니다.'
								}
							/>
						</Grid>
					</Grid>
				</Container>
			</Grid>
		</Container>
	);
};

export default History;
