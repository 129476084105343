import { Box, Container, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Link as ScrollLink } from 'react-scroll';

const SubIndex = ({ data }) => {
	const { t } = useTranslation();
	const theme = useTheme();
	const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));

	return (
		<Box className={matchUpMd ? 'SubIndex-Sticky' : 'M-SubIndex-Sticky'}>
			<Container
				className={'SubIndex'}
				sx={{ height: matchUpMd ? '100px' : '68px' }}
			>
				{data.map((item, index) => (
					<ScrollLink
						key={`SubIndex-${index}`}
						className={matchUpMd ? 'SubIndex-Item' : 'M-SubIndex-Item'}
						activeClass={
							matchUpMd ? 'SubIndex-Item-Active' : 'M-SubIndex-Item-Active'
						}
						spy
						smooth
						offset={matchUpMd ? -160 : -120}
						to={item.value}
					>
						{t(item.text)}
					</ScrollLink>
				))}
			</Container>
		</Box>
	);
};

export default SubIndex;
