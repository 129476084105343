import color from 'style/_color.scss';

const palette = () => {
	return {
		white1: color.white1,
		black1: color.black1,
		black2: color.black2,
		black3: color.black3,
		gray1: color.gray1,
		gray2: color.gray2,
		gray3: color.gray3,
		gray4: color.gray4,
		gray5: color.gray5,
		gray6: color.gray6,
		blue100: color.blue100,
		blue300: color.blue300
	};
};

export default palette;
