import { useLocation, useNavigate } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';

import {
	Box,
	Container,
	Grid,
	Typography,
	useMediaQuery,
	useTheme
} from '@mui/material';

import images from 'assets/images';

import { IconArrowRight } from 'assets/svg';

const MoreViewCard = ({ title = '', menu = '', img, url = '' }) => {
	const { t, i18n } = useTranslation();
	const theme = useTheme();
	const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));
	const navigate = useNavigate();

	const handleNavigatePage = (url) => {
		navigate(url);
	};

	return (
		<Grid item xs={12} md={4}>
			<Box className={'MoreView-Card'} onClick={() => handleNavigatePage(url)}>
				<Box className={'MoreView-Img-Wrapper'}>
					<img src={img} alt={menu} />
				</Box>
				<Box
					p={
						matchUpMd
							? i18n.language === 'jp'
								? '2.6rem'
								: '3.2rem'
							: '2.5rem'
					}
				>
					<Typography
						variant={matchUpMd ? 'h14' : 'caption2'}
						color={'black1'}
						fontWeight={700}
						letterSpacing={i18n.language === 'jp' && '-0.1rem'}
					>
						<Trans i18nKey={title} />
					</Typography>
					<Box
						pt={matchUpMd ? '1.2rem' : '1rem'}
						display={'flex'}
						alignItems={'center'}
					>
						<Typography
							variant={matchUpMd ? 'caption3' : 'caption5'}
							color={'gray6'}
							mr={'0.8rem'}
						>
							{t(menu)}
						</Typography>
						<IconArrowRight
							width={16}
							height={16}
							stroke={theme.palette.gray6}
						/>
					</Box>
				</Box>
			</Box>
		</Grid>
	);
};

const MoreView = () => {
	const { pathname } = useLocation();
	const { t } = useTranslation();
	const theme = useTheme();
	const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));

	return (
		<Container maxWidth={'lg'} className={'px-0'}>
			<Grid
				container
				px={matchUpMd ? 0 : '2rem'}
				pt={matchUpMd ? '12rem' : '6rem'}
				pb={matchUpMd ? '12rem' : '10rem'}
			>
				<Grid container>
					<Typography variant={matchUpMd ? 'h5' : 'h10'} color={'black1'}>
						{t('메뉴.더 둘러보기')}
					</Typography>
				</Grid>
				<Grid
					container
					mt={matchUpMd ? '4rem' : '1.4rem'}
					rowSpacing={matchUpMd ? 0 : 2}
					columnSpacing={3}
				>
					{!pathname.includes('company') && (
						<MoreViewCard
							title={'더 둘러보기.콘텐츠마당, 글로벌 K-플랫폼을 주도합니다'}
							menu={'메뉴.회사 소개'}
							img={images.CompanySub}
							url={'/company'}
						/>
					)}
					{!pathname.includes('business') && (
						<MoreViewCard
							title={
								'더 둘러보기.콘텐츠마당이 하는 일, 전 세계를 연결하는 일입니다'
							}
							menu={'메뉴.사업 소개'}
							img={images.BusinessMain}
							url={'/business'}
						/>
					)}
					{!pathname.includes('service') && (
						<MoreViewCard
							title={'더 둘러보기.가장 트렌디한 팬덤을 위한, FANCAST'}
							menu={'메뉴.서비스 소개'}
							img={images.ServiceSub}
							url={'/service'}
						/>
					)}
					{!pathname.includes('news') && (
						<MoreViewCard
							title={
								'더 둘러보기.콘텐츠마당에 무슨 일이? 최신 정보 놓치지 마세요!'
							}
							menu={'메뉴.뉴스룸'}
							img={images.NewsMain}
							url={'/news'}
						/>
					)}
				</Grid>
			</Grid>
		</Container>
	);
};

export default MoreView;
