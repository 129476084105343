import { useEffect, useState } from 'react';
import {
	Box,
	Container,
	Grid,
	Typography,
	useMediaQuery,
	useTheme
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import images from 'assets/images';

import { MinimalPagination } from 'views/components';

import { newsInfo } from 'data/newsInfo';
import { useNavigate } from 'react-router-dom';

const Info = () => {
	const { t, i18n } = useTranslation();
	const theme = useTheme();
	const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));
	const navigate = useNavigate();

	const limit = 3;
	const [page, setPage] = useState(1);
	const offset = (page - 1) * limit;

	const [currentList, setCurrentList] = useState([]);

	useEffect(() => {
		const tmpList = newsInfo.slice(offset, offset + limit);
		setCurrentList(tmpList);
	}, [page]);

	const handleNavigateDetail = (id) => {
		navigate(`/news/detail/${id}`);
	};

	const renderInfo = () => {
		return currentList.map((item, index) => (
			<Grid item xs={12} md={4} key={`News-Info-${index}`}>
				<Box
					className={'NewsInfo-Card'}
					onClick={() => handleNavigateDetail(item.id)}
				>
					<Box
						className={'NewsInfo-Img-Wrapper'}
						paddingTop={
							matchUpMd ? 'calc(280 / 384 * 100%)' : 'calc(160 / 335 * 100%)'
						}
					>
						<img
							src={item.img}
							alt={item.title}
							style={{ borderRadius: matchUpMd ? '2.4rem' : '1.5rem' }}
						/>
					</Box>
					<Box mt={matchUpMd ? '1.6rem' : '1rem'} px={'0.8rem'}>
						<Typography
							variant={matchUpMd ? 'caption5' : 'caption7'}
							color={'gray5'}
						>
							{i18n.language === 'ko' ? item.date.ko : item.date[i18n.language]}
						</Typography>
					</Box>
					<Box mt={matchUpMd ? '0.8rem' : '0.5rem'} px={'0.8rem'}>
						<Typography
							variant={matchUpMd ? 'caption1' : 'caption3'}
							color={'black2'}
							fontWeight={600}
							whiteSpace={'pre-line'}
						>
							{i18n.language === 'ko'
								? item.title.ko
								: item.title[i18n.language]}
						</Typography>
					</Box>
				</Box>
			</Grid>
		));
	};

	return (
		<Container id={'news-info'} maxWidth={'lg'} className={'px-0'}>
			<Grid
				container
				pt={matchUpMd ? '6rem' : '3rem'}
				px={matchUpMd ? 0 : '2rem'}
				pb={matchUpMd ? '16rem' : '6rem'}
			>
				<Grid
					item
					xs={12}
					container
					direction={'row'}
					justifyContent={matchUpMd ? 'center' : 'flex-start'}
				>
					<Typography variant={matchUpMd ? 'h5' : 'h10'} color={'black2'}>
						{t('메뉴.새소식')}
					</Typography>
				</Grid>
				<Grid
					item
					xs={12}
					container
					direction={'row'}
					justifyContent={matchUpMd ? 'center' : 'flex-start'}
				>
					<Typography
						mt={matchUpMd ? '1.2rem' : '0.6rem'}
						variant={matchUpMd ? 'caption1' : 'caption3'}
						color={'black1'}
					>
						{t('뉴스룸.콘텐츠마당은 지금')}
					</Typography>
				</Grid>
				<Grid
					container
					rowSpacing={matchUpMd ? 7 : 3}
					columnSpacing={3}
					mt={matchUpMd ? '8rem' : '3rem'}
				>
					{renderInfo()}
				</Grid>
				<Grid
					container
					direction={'row'}
					justifyContent={'flex-end'}
					mt={matchUpMd ? '7rem' : '4rem'}
				>
					<MinimalPagination
						page={page}
						setPage={setPage}
						totalPage={Math.ceil(newsInfo.length / 3)}
					/>
				</Grid>
			</Grid>
		</Container>
	);
};

export default Info;
