const typography = () => {
	return {
		fontFamily: "'Pretendard JP', 'Pretendard'",
		h1: {
			fontSize: '6rem',
			fontWeight: 700,
			letterSpacing: '-0.12rem'
		},
		h2: {
			fontSize: '4.6rem',
			fontWeight: 700,
			letterSpacing: '-0.092rem'
		},
		h3: {
			fontSize: '4.4rem',
			fontWeight: 700,
			letterSpacing: '-0.088rem'
		},
		h4: {
			fontSize: '4.2rem',
			fontWeight: 700,
			letterSpacing: '-0.084rem'
		},
		h5: {
			fontSize: '4rem',
			fontWeight: 700,
			letterSpacing: '-0.08rem'
		},
		h6: {
			fontSize: '3.8rem',
			fontWeight: 700,
			letterSpacing: '-0.076rem'
		},
		h7: {
			display: 'block',
			fontSize: '3.6rem',
			fontWeight: 700,
			letterSpacing: '-0.072rem'
		},
		h8: {
			display: 'block',
			fontSize: '3.4rem',
			fontWeight: 700,
			letterSpacing: '-0.068rem'
		},
		h9: {
			display: 'block',
			fontSize: '3.2rem',
			fontWeight: 700,
			letterSpacing: '-0.064rem'
		},
		h10: {
			display: 'block',
			fontSize: '3rem',
			fontWeight: 700,
			letterSpacing: '-0.06rem'
		},
		h11: {
			display: 'block',
			fontSize: '2.8rem',
			fontWeight: 700,
			letterSpacing: '-0.056rem'
		},
		h12: {
			display: 'block',
			fontSize: '2.6rem',
			fontWeight: 700,
			letterSpacing: '-0.05rem'
		},
		h13: {
			display: 'block',
			fontSize: '2.4rem',
			fontWeight: 700,
			letterSpacing: '-0.048rem'
		},
		h14: {
			display: 'block',
			fontSize: '2.2rem',
			fontWeight: 700,
			letterSpacing: '-0.044rem'
		},
		caption1: {
			fontSize: '2rem',
			fontWeight: 400,
			letterSpacing: '-0.04rem'
		},
		caption2: {
			fontSize: '1.8rem',
			fontWeight: 400,
			letterSpacing: '-0.036rem'
		},
		caption3: {
			fontSize: '1.6rem',
			fontWeight: 400,
			letterSpacing: '-0.032rem'
		},
		caption4: {
			fontSize: '1.5rem',
			fontWeight: 400,
			letterSpacing: '-0.028rem'
		},
		caption5: {
			fontSize: '1.4rem',
			fontWeight: 400,
			letterSpacing: '-0.028rem'
		},
		caption6: {
			fontSize: '1.3rem',
			fontWeight: 400,
			letterSpacing: '-0.026rem'
		},
		caption7: {
			fontSize: '1.2rem',
			fontWeight: 400,
			letterSpacing: '-0.024rem'
		},
		caption8: {
			fontSize: '1.1rem',
			fontWeight: 400,
			letterSpacing: '-0.022rem'
		}
	};
};

export default typography;
