import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
	Box,
	ButtonBase,
	Fade,
	Typography,
	useMediaQuery,
	useTheme
} from '@mui/material';

import { IconArrowDropdownBottom, IconWorld } from 'assets/svg';

const LanguageDropdown = () => {
	const { t, i18n } = useTranslation();
	const theme = useTheme();
	const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));

	const dropdownRef = useRef(null);
	const [isOpen, setIsOpen] = useState(false);

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	const handleClickOutside = (event) => {
		if (!dropdownRef.current.contains(event.target)) {
			setIsOpen(false);
		}
	};

	const handleOpenDropdown = () => {
		setIsOpen(!isOpen);
	};

	const handleChangeLanguage = (language) => {
		i18n.changeLanguage(language).then((err, t) => {});
		setIsOpen(!isOpen);
	};

	const getLanguageText = useCallback(() => {
		switch (i18n.language) {
			case 'ko-KR':
			case 'ko':
				return t('언어.한글');
			case 'en':
				return t('언어.영어');
			case 'jp':
				return t('언어.일어');
			default:
				return '';
		}
	}, [i18n]);

	const getStyleMobileCurrentLanguage = useCallback(
		(language) => {
			let style = ' M-LanguageDropdown-Item-Bold';

			switch (i18n.language) {
				case 'ko-KR':
				case 'ko':
					if (language !== 'ko') style = '';
					break;
				case 'en':
					if (language !== 'en') style = '';
					break;
				case 'jp':
					if (language !== 'jp') style = '';
					break;
				default:
					break;
			}
			return style;
		},
		[i18n]
	);

	if (matchUpMd) {
		return (
			<Box ref={dropdownRef} position={'relative'} ml={'15px'}>
				<ButtonBase
					disableRipple
					className={'LanguageDropdown'}
					onClick={handleOpenDropdown}
				>
					<Typography
						variant={'caption3'}
						fontWeight={500}
						color={'black2'}
						mr={'0.8rem'}
					>
						{getLanguageText()}
					</Typography>
					<IconArrowDropdownBottom
						width={10}
						height={7}
						fill={theme.palette.black2}
					/>
				</ButtonBase>
				{isOpen && (
					<Fade in={isOpen}>
						<Box className={'LanguageDropdown-Item-Wrapper'}>
							<ButtonBase
								disableRipple
								className={'LanguageDropdown-Item'}
								onClick={() => handleChangeLanguage('ko')}
							>
								{t('언어.한글')}
							</ButtonBase>
							<ButtonBase
								disableRipple
								className={'LanguageDropdown-Item'}
								onClick={() => handleChangeLanguage('en')}
							>
								{t('언어.영어')}
							</ButtonBase>
							<ButtonBase
								disableRipple
								className={'LanguageDropdown-Item'}
								onClick={() => handleChangeLanguage('jp')}
							>
								{t('언어.일어')}
							</ButtonBase>
						</Box>
					</Fade>
				)}
			</Box>
		);
	}

	return (
		<Box ref={dropdownRef} position={'relative'} mr={'8px'}>
			<ButtonBase
				disableRipple
				className={'M-LanguageDropdown'}
				onClick={handleOpenDropdown}
			>
				<IconWorld width={28} height={28} stroke={theme.palette.black2} />
			</ButtonBase>
			{isOpen && (
				<Fade in={isOpen}>
					<Box className={'M-LanguageDropdown-Item-Wrapper'}>
						<ButtonBase
							disableRipple
							className={
								'M-LanguageDropdown-Item' + getStyleMobileCurrentLanguage('ko')
							}
							onClick={() => handleChangeLanguage('ko')}
						>
							{t('언어.한글')}
						</ButtonBase>
						<ButtonBase
							disableRipple
							className={
								'M-LanguageDropdown-Item' + getStyleMobileCurrentLanguage('en')
							}
							onClick={() => handleChangeLanguage('en')}
						>
							{t('언어.영어')}
						</ButtonBase>
						<ButtonBase
							disableRipple
							className={
								'M-LanguageDropdown-Item' + getStyleMobileCurrentLanguage('jp')
							}
							onClick={() => handleChangeLanguage('jp')}
						>
							{t('언어.일어')}
						</ButtonBase>
					</Box>
				</Fade>
			)}
		</Box>
	);
};

export default LanguageDropdown;
