export const headerNav = [
	{
		main: {
			text: '메뉴.회사 소개',
			url: '/company'
		},
		sub: [
			{
				text: '메뉴.회사 개요',
				scrollId: 'company-intro'
			},
			{
				text: '메뉴.히스토리',
				scrollId: 'company-history'
			},
			{
				text: '메뉴.경영진 소개',
				scrollId: 'company-management'
			},
			{
				text: '메뉴.찾아오시는 길',
				scrollId: 'company-location'
			}
		]
	},
	{
		main: {
			text: '메뉴.사업 소개',
			url: '/business'
		},
		sub: [
			{
				text: '메뉴.사업 개요',
				scrollId: 'business-intro'
			},
			{
				text: '메뉴.파트너 소개',
				scrollId: 'business-partner'
			},
			{
				text: '메뉴.연계 사업',
				scrollId: 'business-connection'
			},
			{
				text: '메뉴.제휴 문의',
				scrollId: 'business-contact'
			}
		]
	},
	{
		main: {
			text: '메뉴.서비스 소개',
			url: '/service'
		},
		sub: [
			// {
			// 	text: '메뉴.팬캐스트',
			// 	scrollId: 'service-fancast'
			// }
		]
	},
	{
		main: {
			text: '메뉴.뉴스룸',
			url: '/news'
		},
		sub: [
			{
				text: '메뉴.새소식',
				scrollId: 'news-info'
			},
			{
				text: '메뉴.보도자료',
				scrollId: 'news-official'
			}
		]
	}
];
