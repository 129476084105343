import images from 'assets/images';

export const newsInfo = [
	{
		id: 1,
		date: {
			ko: '2024-03-21',
			en: 'March 21, 2024',
			jp: '2024年 3月 22日'
		},
		title: {
			ko: '사무실 확장 이전 안내',
			en: 'Office expansion relocation announcement',
			jp: 'オフィス拡張移転のご案内'
		},
		img: images.News1,
		contents: {
			ko: '안녕하십니까? (주)콘텐츠마당입니다.\n당사는 새로운 도약을 맞이하고자 2024년 4월 1일자로 사무실을 확장 이전하게 되었습니다.\n이번 사무실 이전을 계기로 한층 발전된 모습 보여 드리겠습니다.\n항상 보내주시는 관심과 성원에 감사드리며, 앞으로도 변함없는 사랑과 관심 부탁드립니다.\n감사합니다.\n\n- 이전일자 : 2024년 4월 1일 월요일\n- 이전주소 : 서울시 영등포구 영신로 166, 908호(영등포반도아이비밸리)\n- 대표전화 : 02-2671-7797',
			en: 'Hello, we’re Contents Madang.\nWe’re excited to announce that as of April 1, 2024, we’re moving to a new location.\nWe look forward to showing you how we’ve evolved with this move.\nThank you for your interest and support, and we look forward to your continued love and interest in the future.\nThank you.\n\n- Relocation date : Monday, April 1, 2024\n- Address : #908, 166, Yeongsin-ro, Yeongdeungpo-gu, Seoul, Republic of Korea (Yeongdeungpo Bando Ivy Valley)\n- Tel : +82-2-2671-7797',
			jp: '皆様、\n(株)コンテンツマダンでございます。この度はご挨拶申し上げます。\n\n弊社は新たな飛躍を迎えるため、2024年4月1日付で事務所を拡張移転する運びとなりました。この貴重な機会に、皆様により一層の発展をお見せできることを心より嬉しく存じます。\n\nいつもお寄せいただくご支援とご理解に心より感謝申し上げます。今後も変わらぬご愛顧を賜りますよう、心よりお願い申し上げます。\nこれからも一層のご支援を賜りますよう、何卒よろしくお願い申し上げます。\n\n移転の詳細は以下の通りです。\n\n移転日：2024年4月1日(月曜日)\n移転先住所：ソウル市永登浦区永新路166、908号(永登浦半島アイビーバレー)\n代表電話：+82 2-2671-7797'
		}
	}
];
