import { Routes, Route, Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { MainLayout } from 'layouts';

import {
	CompanyRootPage,
	BusinessRootPage,
	ServiceRootPage,
	NewsRootPage,
	NewsDetailPage
} from 'views';

const RouteConfig = () => {
	const { t } = useTranslation();

	return (
		<Routes>
			<Route path={'/'} element={<Navigate to={'/company'} />} />
			<Route element={<MainLayout />}>
				<Route path={'/company'} element={<CompanyRootPage />} />
				<Route path={'/business'} element={<BusinessRootPage />} />
				<Route path={'/service'} element={<ServiceRootPage />} />
				<Route path={'/news'} element={<NewsRootPage />} />
				<Route path={'/news/detail/:id'} element={<NewsDetailPage />} />
			</Route>
		</Routes>
	);
};

export default RouteConfig;
