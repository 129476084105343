import {
	Box,
	Container,
	Typography,
	useMediaQuery,
	useTheme
} from '@mui/material';
import { Trans } from 'react-i18next';

import images from 'assets/images';

const MainImage = () => {
	const theme = useTheme();
	const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));

	return (
		<Box
			className={matchUpMd ? 'MainImage' : 'M-MainImage'}
			sx={{
				background: `linear-gradient(rgba(0, 0, 0, 0.6) 100%, rgba(0, 0, 0, 0.6) 100%), url(${images.BusinessMain})`,
				backgroundPosition: 'center',
				backgroundSize: 'cover'
			}}
		>
			<Container className={'MainImage-Title'}>
				<Typography
					variant={matchUpMd ? 'h1' : 'h11'}
					color={'white1'}
					align={'center'}
				>
					<Trans
						i18nKey={
							'사업 소개.콘텐츠마당이 하는 일, 전 세계를 연결하는 일입니다'
						}
					/>
				</Typography>
			</Container>
		</Box>
	);
};

export default MainImage;
