import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
	Box,
	ButtonBase,
	Container,
	Drawer,
	Grid,
	Link,
	Typography,
	useMediaQuery,
	useTheme
} from '@mui/material';

import * as ReactScroll from 'react-scroll';
import PerfectScrollbar from 'react-perfect-scrollbar';

import images from 'assets/images';
import {
	IconMobileHamburger,
	IconClose,
	IconArrowSimpleTop,
	IconArrowSimpleDown
} from 'assets/svg';

import { headerNav } from 'navigation';

import { LanguageDropdown } from 'layouts/components';

import { useCallback, useState } from 'react';

const Header = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const theme = useTheme();
	const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));

	const scroller = ReactScroll.scroller;

	const [isOpenMobileNav, setIsOpenMobileNav] = useState(false);
	const [currentOpenNavList, setCurrentOpenNavList] = useState([]);

	const getIsExistCurrentOpen = useCallback(
		(categoryUrl) => {
			return (
				currentOpenNavList.findIndex((item) => item === categoryUrl) !== -1
			);
		},
		[currentOpenNavList]
	);

	const handleClickCategory = (categoryUrl) => {
		const findIndexIndex = currentOpenNavList.findIndex(
			(item) => item === categoryUrl
		);

		let tmpCurrentOpenNavList = currentOpenNavList.slice();
		if (findIndexIndex === -1) {
			tmpCurrentOpenNavList.push(categoryUrl);
		} else {
			tmpCurrentOpenNavList.splice(findIndexIndex, 1);
		}
		setCurrentOpenNavList(tmpCurrentOpenNavList);
	};

	const handleNavigateAndScroll = async (navUrl, scrollId) => {
		if (isOpenMobileNav) {
			setIsOpenMobileNav(false);
		}

		await navigate(navUrl);
		await scroller.scrollTo(scrollId, {
			smooth: true,
			spy: true,
			offset:
				navUrl === '/news' ? (matchUpMd ? -60 : -50) : matchUpMd ? -160 : -120
		});
	};
	const handleNavigateHome = () => {
		navigate('/company');
	};

	const handleClickMobileNav = () => {
		setIsOpenMobileNav(!isOpenMobileNav);
	};

	return (
		<Box className={'Header-Sticky'}>
			<Container
				className={matchUpMd ? 'Header' : 'M-Header'}
				maxWidth={'lg'}
				sx={{ px: 0 }}
			>
				<Grid container height={'100%'} px={matchUpMd ? 0 : '2rem'}>
					<Grid item xs={3} className={'Header-Left'}>
						<img
							src={images.ContentsMadang}
							alt={'ContentsMadang'}
							className={matchUpMd ? 'Header-Logo' : 'M-Header-Logo'}
							onClick={handleNavigateHome}
						/>
						{matchUpMd && <LanguageDropdown />}
					</Grid>
					{matchUpMd && (
						<Grid item xs={9} className={'Header-Right'}>
							<ul className={'Header-Nav'}>
								{headerNav.map((nav, index) => (
									<li key={`Header-Nav-${index}`} className={'Header-Nav-Item'}>
										<Link
											href={nav.main.url}
											fontWeight={pathname === nav.main.url ? 700 : 400}
										>
											{t(nav.main.text)}
										</Link>
										{nav.sub.length > 0 && (
											<ul className={'Header-Nav-Item-Menu'}>
												{nav.sub.map((item, index) => (
													<li
														key={`Header-Nav-Menu-Item-${index}`}
														className={'Header-Nav-Item-Menu-Item'}
													>
														<Link
															onClick={() =>
																handleNavigateAndScroll(
																	nav.main.url,
																	item.scrollId
																)
															}
														>
															{t(item.text)}
														</Link>
													</li>
												))}
											</ul>
										)}

									</li>
								))}
							</ul>
						</Grid>
					)}
					{!matchUpMd && (
						<Grid item xs={9} className={'Header-Right'}>
							{!matchUpMd && <LanguageDropdown />}
							<ButtonBase
								disableRipple
								disableTouchRipple
								onClick={handleClickMobileNav}
							>
								{isOpenMobileNav ? (
									<IconClose
										width={28}
										height={28}
										stroke={theme.palette.black1}
									/>
								) : (
									<IconMobileHamburger
										width={28}
										height={28}
										stroke={theme.palette.black1}
									/>
								)}
							</ButtonBase>
						</Grid>
					)}
					{!matchUpMd && (
						<Drawer
							anchor={'right'}
							open={isOpenMobileNav}
							onClose={handleClickMobileNav}
							PaperProps={{
								sx: {
									mt: '57px',
									width: '100%'
								}
							}}
						>
							<PerfectScrollbar>
								<Box className={'M-Header-Nav'}>
									{headerNav.map((nav, index) => (
										<Box key={`M-Header-Nav-${index}`}>
											<Box className={'M-Header-Nav-Item'}>
												<ButtonBase
													disableRipple
													disableTouchRipple
													href={nav.main.url}
													sx={{
														px: '2rem',
														width: '100%'
													}}
												>
													<Typography
														variant={'h13'}
														color={'black1'}
														align={'left'}
														sx={{ width: '100%' }}
													>
														{t(nav.main.text)}
													</Typography>
												</ButtonBase>
												{nav.sub.length > 0 && (
													<ButtonBase
														disableRipple
														disableTouchRipple
														sx={{ px: '2rem' }}
														onClick={() => handleClickCategory(nav.main.url)}
													>
														{getIsExistCurrentOpen(nav.main.url) ? (
															<IconArrowSimpleTop
																width={14}
																height={7}
																stroke={theme.palette.black2}
															/>
														) : (
															<IconArrowSimpleDown
																width={14}
																height={7}
																stroke={theme.palette.black2}
															/>
														)}
													</ButtonBase>
												)}
											</Box>
											{getIsExistCurrentOpen(nav.main.url) &&
												nav.sub.map((item, index) => (
													<ButtonBase
														disableRipple
														disableTouchRipple
														className={'M-Header-Nav-Sub-Item'}
														onClick={() =>
															handleNavigateAndScroll(
																nav.main.url,
																item.scrollId
															)
														}
													>
														{t(item.text)}
													</ButtonBase>
												))}
										</Box>
									))}
								</Box>
							</PerfectScrollbar>
						</Drawer>
					)}
				</Grid>
			</Container>
		</Box>
	);
};

export default Header;
