import { useTranslation } from 'react-i18next';
import {
	Box,
	ButtonBase,
	Container,
	Typography,
	useMediaQuery,
	useTheme
} from '@mui/material';

import images from 'assets/images';
import { IconArrowRight } from 'assets/svg';

import { ResponsiveImage } from 'views/components';

const Contact = () => {
	const { t, i18n } = useTranslation();
	const theme = useTheme();
	const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));

	const handleClickMail = () => {
		document.location.href = 'mailto:contact@contentsmadang.com';
	};

	return (
		<Box
			id={'business-contact'}
			className={matchUpMd ? 'BusinessContact-Img' : 'M-BusinessContact-Img'}
			sx={{
				background: `url(${images.BusinessContact})`,
				backgroundPosition: 'center',
				backgroundSize: 'cover'
			}}
		>
			<Container
				maxWidth={'lg'}
				className={'BusinessContact'}
				sx={{ px: matchUpMd ? 0 : i18n.language === 'ko' ? '4rem' : '2rem' }}
			>
				<Typography variant={matchUpMd ? 'h5' : 'h10'} color={'black2'}>
					{t('메뉴.제휴 문의')}
				</Typography>
				<Typography
					mt={matchUpMd ? '1.2rem' : '1rem'}
					variant={matchUpMd ? 'caption2' : 'caption4'}
					color={'black2'}
				>
					{t('제휴 문의.(주)콘텐츠마당의 파트너가 되어보세요')}
				</Typography>
				<Typography
					mt={matchUpMd ? '5rem' : '3rem'}
					display={'block'}
					variant={matchUpMd ? 'h9' : 'caption2'}
					color={'black1'}
					fontWeight={700}
				>
					{t('제휴 문의.contact@contentsmadang.com')}
				</Typography>
				<ButtonBase
					className={'BusinessContact-Button'}
					onClick={handleClickMail}
					sx={{
						mt: matchUpMd ? '2rem' : '1rem',
						py: matchUpMd ? '1rem' : '0.8rem',
						px: matchUpMd ? '2rem' : '1.6rem'
					}}
				>
					<Typography
						variant={matchUpMd ? 'caption3' : 'caption5'}
						color={'white1'}
						mr={'0.5rem'}
					>
						{t('제휴 문의.지금 메일 보내기')}
					</Typography>
					<IconArrowRight
						width={15}
						height={10}
						stroke={theme.palette.white1}
					/>
				</ButtonBase>
			</Container>
		</Box>
	);
};

export default Contact;
