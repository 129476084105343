import { Fragment, useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import _ from 'lodash';

import {
	Box,
	Container,
	Grid,
	Typography,
	useMediaQuery,
	useTheme
} from '@mui/material';

import images from 'assets/images';

const mediaGroupPartner = [
	{ source: images.IconSportSeoul, alt: '스포츠서울' },
	{ source: images.IconIlganSports, alt: '일간스포츠' },
	{ source: images.IconHanteoChart, alt: '한터차트' },
	{ source: images.IconCircleChart, alt: '써클차트' },
	{ source: images.IconEna, alt: 'ena' }
];

const entertainment = [
	{ source: images.IconHybe, alt: 'hybe' },
	{ source: images.IconSm, alt: 'sm' },
	{ source: images.IconJyp, alt: 'jyp' },
	{ source: images.IconStarShip, alt: 'starship' },
	{ source: images.IconRbw, alt: 'rbw' },
	{ source: images.IconCube, alt: 'cube' },
	{ source: images.IconJellyfish, alt: 'jellyfish' },
	{ source: images.IconKq, alt: 'kq' },
	{ source: images.IconFantagio, alt: 'fantagio' },
	{ source: images.IconFandF, alt: 'f&f' },
	{ source: images.IconFirstOne, alt: 'firstone' },
	{ source: images.IconNch, alt: 'nch' },
	{ source: images.IconMakeStar, alt: 'makestar' },
	{ source: images.IconWoollim, alt: 'woollim' },
	{ source: images.Icon143, alt: '143' }
];

const contentsPartner = [
	{ source: images.IconKepa, alt: '한국연예제작자협회' },
	{ source: images.IconLiveConnect, alt: 'live connect' },
	{ source: images.IconIdolChamp, alt: 'idol champ' },
	{ source: images.IconMubeat, alt: 'mubeat' },
	{ source: images.IconPodoal, alt: 'podoal' },
	{ source: images.IconWhosFan, alt: 'whosfan' },
	{ source: images.IconBlip, alt: 'blip' }
];

const partner = [{ source: images.IconFinger, alt: 'finger' }];

const Partner = () => {
	const { t, i18n } = useTranslation();
	const theme = useTheme();
	const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));

	const renderPartner = useCallback(
		(title, subtitle, icons) => {
			return (
				<Fragment>
					<Grid
						container
						display={'flex'}
						alignItems={'center'}
						mt={matchUpMd ? '8rem' : '4rem'}
					>
						<Grid item xs={12} md={'auto'} pr={matchUpMd ? '3rem' : 0}>
							<Typography
								variant={matchUpMd ? 'h13' : 'caption2'}
								color={'black1'}
								fontWeight={700}
							>
								{t(title)}
							</Typography>
						</Grid>
						{!_.isEmpty(subtitle) && (
							<Grid item xs={12} md>
								<Typography
									variant={matchUpMd ? 'caption3' : 'caption6'}
									color={'gray6'}
									letterSpacing={i18n.language === 'ko' && '-0.07rem'}
								>
									{t(subtitle)}
								</Typography>
							</Grid>
						)}
					</Grid>
					<Grid
						container
						pt={'1.2rem'}
						rowSpacing={matchUpMd ? 3 : 1}
						columnSpacing={matchUpMd ? 3 : 1}
					>
						{icons.map((item, index) => (
							<Grid key={`${title}-partner-${index}`} item xs={4} md={2}>
								<Box
									className={'Business-Partner-Item'}
									py={matchUpMd ? '1.5rem' : '1rem'}
									px={matchUpMd ? '1rem' : '0.4rem'}
									borderRadius={matchUpMd ? '2.4rem' : '1.2rem'}
								>
									<Box className={'Business-Partner-Icon'}>
										<img src={item.source} alt={item.alt} />
									</Box>
								</Box>
							</Grid>
						))}
					</Grid>
				</Fragment>
			);
		},
		[matchUpMd]
	);

	return (
		<Container maxWidth={'lg'}>
			<Grid
				container
				px={matchUpMd ? 0 : '2rem'}
				py={matchUpMd ? '12rem' : '8rem'}
			>
				{/* 타이틀 */}
				<Grid item xs={12}>
					<Typography variant={matchUpMd ? 'h5' : 'h10'} color={'black1'}>
						<Trans
							i18nKey={
								matchUpMd
									? '파트너 소개.콘텐츠마당의 공식 파트너사'
									: '파트너 소개.M콘텐츠마당의 공식 파트너사'
							}
						/>
					</Typography>
				</Grid>
				<Grid item xs={12} mt={matchUpMd ? '1.2rem' : '1.5rem'}>
					<Typography
						variant={matchUpMd ? 'caption1' : 'caption4'}
						color={'black1'}
					>
						{t(
							'파트너 소개.(주)콘텐츠마당은 국내외 다양한 협력 파트너사들과 함께 고유의 가치를 만들어갑니다.'
						)}
					</Typography>
				</Grid>
				{/*	미디어 그룹 */}
				{renderPartner('파트너 소개.미디어 그룹', '', mediaGroupPartner)}

				{/*	엔터테인먼트사 */}
				{renderPartner('파트너 소개.엔터테인먼트사', '', entertainment)}

				{/*	콘텐츠 제휴사 */}
				{renderPartner('파트너 소개.콘텐츠 제휴사', '', contentsPartner)}

				{/*	파트너사 */}
				{renderPartner('파트너 소개.파트너사', '', partner)}
			</Grid>
		</Container>
	);
};

export default Partner;
