import { MoreView, SubIndex } from 'views/components';

import {
	MainImage,
	FancastSection,
} from 'views/service/components';

const serviceSubIndex = [
	{
		text: '메뉴.팬캐스트',
		value: 'service-fancast'
	},
	{
		text: '메뉴.팬심',
		value: 'service-fanseem'
	}
];

const ServiceRootPage = () => {
	return (
		<div>
			<MainImage />
			{/*<SubIndex data={serviceSubIndex} />*/}
			<FancastSection />
			<MoreView />
		</div>
	);
};

export default ServiceRootPage;
